<template>
  <div>
    <we-card :title="`イベント管理 / ${isEdit ? '変更' : '新規'}`">
      <slot>
        <md-steppers :md-active-step.sync="currentStep" md-linear class="stepper">
          <md-step id="first" md-label="イベント種類選択" :md-done.sync="first" :md-editable="!isEdit">
            <div class="md-layout md-alignment-center" style="margin: 50px; padding-bottom: 50px;">
              <div class="md-layout-item md-size-35 md-xsmall-size-100">
                <we-plus-card title="握手会" description="ファンと1 on 1で話す" @click="chooseEventCategory(1)" />
              </div>
              <div class="md-layout-item md-size-30 md-xsmall-size-100">
                <we-plus-card title="ライブ配信" description="ファンと交流する" @click="chooseEventCategory(3)" />
              </div>
              <div class="md-layout-item md-size-35 md-xsmall-size-100">
                <we-plus-card title="応援会" description="1 on Nでの交流会を開催する" @click="chooseEventCategory(2)" />
              </div>
            </div>
            <div class="step-action">
              <we-button size="small" @click="cancel" outline="red">キャンセル</we-button>
            </div>
          </md-step>
          <md-step id="second" md-label="宣伝情報設定" :md-done.sync="second">
            <EventForm v-model="event" ref="eventForm" />
            <div class="step-action">
              <we-button size="small" @click="cancel" outline="red">キャンセル</we-button>
              <we-button size="small" v-if="!isEdit" @click="setStepDone('first', 'first')" outline="red">戻る</we-button>
              <we-button size="small" @click="toThirdForm">次へ</we-button>
            </div>
          </md-step>
          <md-step id="third" md-label="料金設定" :md-done.sync="third">
            <TicketForm v-model="event" ref="ticketForm" />
            <div class="step-action">
              <we-button size="small" @click="cancel" outline="red">キャンセル</we-button>
              <we-button size="small" @click="setStepDone('second', 'second')" outline="red">戻る</we-button>
              <we-button size="small" outline="red">プレビュー</we-button>
              <we-button size="small" @click="saveEvent(false)" outline="red">保存</we-button>
              <we-button size="small" @click="saveEvent(true)">公開</we-button>
            </div>
          </md-step>
        </md-steppers>
      </slot>
    </we-card>
  </div>
</template>
<script>
import { ajax, common, config, constant } from '../../utils';
import EventForm from './EventForm';
import TicketForm from './TicketForm';

export default {
  components: {
    EventForm,
    TicketForm,
  },
  data() {
    return {
      currentStep: this.$route.params.id > 0 ? 'second' : 'first',
      first: false,
      second: false,
      third: false,
      event: { eventType: null, status: 1, tickets: [], services: [{}] },
      error: {},
    }
  },
  computed: {
    pk() {
      return this.$route.params.id;
    },
    isEdit() {
      return this.pk > 0;
    },
  },
  created() {
    if (this.isEdit) {
      this.getEventDetail();
    }
  },
  methods: {
    chooseEventCategory(eventType) {
      this.event.eventType = eventType;
      this.setStepDone('first', 'second');
    },
    setStepDone (id, index) {
      this[id] = true;

      if (index) {
        this.currentStep = index
      }
    },
    async toThirdForm() {
      const isValid = await this.$refs.eventForm.validate();
      if (isValid) {
        this.setStepDone('second', 'third');
      }
    },
    cancel() {
      this.$router.push({ name: 'events' })
    },
    getEventDetail() {
      const vm = this;
      common.loading();
      ajax.get(common.formatStr(config.api.event.detail, this.pk)).then(data => {
        data.startTime = new Date(data.startTime);
        data.endTime = new Date(data.endTime);
        data.saleStartTime = new Date(data.saleStartTime);
        data.saleEndTime = new Date(data.saleEndTime);
        if (!('tickets' in data)) {
          data.tickets = [];
        }
        if (!('services' in data)) {
          data.services = [{templates: []}];
        }
        vm.event = data;
      }).finally(() => {
        common.loaded();
      });
    },
    async saveEvent(isPublish) {
      const vm = this;
      vm.error = {};
      const isValid = await this.$refs.ticketForm.validate();
      if (isValid) {
        vm.event.status = isPublish === true ? 1 : 0;
        if (vm.isEdit) {
          common.loading();
          ajax.put(common.formatStr(config.api.event.detail, vm.pk), vm.event).then(async() => {
            for (const ticket of vm.event.tickets) {
              if (ticket.id && ticket.is_deleted === true) {
                // チケット削除の場合
                await ajax.delete(common.formatStr(config.api.event.ticket.detail, vm.pk, ticket.id))
              } else if (ticket.id) {
                // チケット変更の場合
                await ajax.put(common.formatStr(config.api.event.ticket.detail, vm.pk, ticket.id), ticket)
              } else if (ticket.is_deleted !== true) {
                // チケット追加の場合
                await ajax.post(common.formatStr(config.api.event.ticket.create, vm.pk), ticket)
              }
            }
            const service = vm.event.services[0];
            if (service.id) {
              await ajax.put(common.formatStr(config.api.event.service.detail, vm.pk, service.id), service);
              if (service.templates) {
                for (const template of service.templates) {
                  if (template.id && template.is_deleted === true) {
                    // テンプレート削除の場合
                    await ajax.delete(common.formatStr(config.api.event.service.template.detail, vm.pk, service.id, template.id))
                  } else if (template.id) {
                    // テンプレート変更の場合
                    await ajax.put(common.formatStr(config.api.event.service.template.detail, vm.pk, service.id, template.id), template)
                  } else if (template.is_deleted !== true) {
                    // テンプレート追加の場合
                    await ajax.post(common.formatStr(config.api.event.service.template.create, vm.pk, service.id), template)
                  }
                }
              }
            } else {
              await ajax.post(common.formatStr(config.api.event.service.create, vm.pk), service);
            }
          }).then(() => {
            vm.$toasted.success(constant.SUCCESS.EVENT_SAVED);
            vm.$router.push({name: 'events'});
          }).catch(error => {
            if (error.errors) {
              vm.error = error.errors;
            } else {
              vm.$toasted.error(constant.ERROR.UNEXCEPTED);
            }
          }).finally(() => {
            common.loaded();
          });
        } else {
          common.loading();
          ajax.post(config.api.event.create, vm.event).then(data => {
            vm.$toasted.success(constant.SUCCESS.EVENT_SAVED);
            vm.$router.push({name: 'events-edit', params: {id: data.id}})
          }).catch(error => {
            if (error.errors) {
              vm.error = error.errors;
            } else {
              vm.$toasted.error(constant.ERROR.UNEXCEPTED);
            }
          }).finally(() => {
            common.loaded();
          });
        }
      }
    },
  }
}
</script>
<style scoped>
.md-card >>> div.md-steppers-navigation {
  box-shadow: none;
  margin-top: 20px;
}
.stepper .step-action {
  text-align: center;
}
</style>