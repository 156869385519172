<template>
  <form class="we-form">
    <validation-observer ref="observer">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <div class="md-layout-item md-size-100">
            <label for="id_title">タイトル<span class="required">*</span></label>
            <we-input
              id="id_title" 
              :rules="{required: true}" 
              v-model="value.title"
              :error="error.title" 
              name="タイトル"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <label for="id_organization_id">組織<span class="required">*</span></label>
            <we-select id="id_organization_id"
              :rules="{required: true}"
              v-model.number="value.organizationId"
              :options="orgOptions"
              :error="error.organizationId"
              name="組織"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <label for="id_artist">アーティスト<span class="required">*</span></label>
            <we-select id="id_artist"
              :rules="{required: true}"
              v-model.number="value.liverId"
              :options="artistOptions"
              :error="error.liverId"
              name="アーティスト"
            />
          </div>
          <div class="md-layout-item md-size-100 we-form-field" style="margin-bottom: 20px;">
            <label for="id_start_dt">開催日時<span class="required">*</span></label>
            <we-date-picker
              id="id_start_dt"
              :rules="{required: true}"
              v-model="value.startTime"
              name="開催日時"
            />
          </div>
          <div class="md-layout-item md-size-100 we-form-field" style="margin-bottom: 20px;">
            <label for="id_end_dt">終了日時<span class="required">*</span></label>
            <we-date-picker
              id="id_end_dt"
              :rules="{required: true}"
              v-model="value.endTime"
              name="終了日時"
            />
          </div>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100" style="vertical-align: top;">
          <div class="md-layout-item md-size-100">
            <label for="id_event_description" style="vertical-align: inherit;">イベント説明</label>
            <we-textarea v-model="value.description" styles="width: 250px; height: 300px;"></we-textarea>
          </div>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-50 md-small-size-100" style="vertical-align: top;">
          <label style="width: 130px; vertical-align: inherit;">カバー画像<span class="required">*</span></label>
          <we-image
            v-model="value.imageCover" 
            :rules="{required: true}"
            name="カバー画像"
          />
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100" style="vertical-align: top;">
          <label style="width: 130px; vertical-align: inherit;">背景画像<span class="required">*</span></label>
          <we-image
            v-model="value.imageBackground" 
            :rules="{required: true}"
            name="背景画像"
            type="square"
            :aspectRatioX="9"
            :aspectRatioY="16"
          />
        </div>
      </div>
    </validation-observer>
  </form>
</template>
<script>
import { ajax, config } from '../../utils';

export default {
  props: {
    value: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      artistOptions: [],
      orgOptions: [],
      error: {},
    }
  },
  created() {
    this.getArtistChoice();
    this.getOrganizations();
  },
  methods: {
    validate() {
      return this.$refs.observer.validate();
    },
    getArtistChoice() {
      const vm = this;
      ajax.get(config.api.artist.list).then(data => {
        vm.artistOptions = data.map(i => ({'value': i.liverId, 'text': i.nickName}));
      });
    },
    getOrganizations() {
      const vm = this;
      ajax.get(config.api.organization.list).then(data => {
        vm.orgOptions = data.map(item => ({
          'value': item.organizationId,
          'text': item.organizationName,
        }));
        if (data.length === 1) {
          // vm.artist.organizationId = vm.orgOptions[0].value;
        }
      }); 
    },
  }
}
</script>
<style scoped>
.we-form >>> label {
  width: 120px;
}
</style>