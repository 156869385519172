<template>
  <form class="we-form">
    <validation-observer ref="observer">
      <span class="form-title md-subheading">{{value.category}}チケット情報</span>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-25 we-form-field">
          <label for="id_ticket_count">発行枚数<span class="required">*</span></label>
          <we-input
            id="id_ticket_count" 
            :rules="{required: true}" 
            v-model.number="value.ticketCount"
            name="発行枚数"
          />
        </div>
        <div class="md-layout-item md-size-25 we-form-field" style="margin-bottom: 20px;">
          <label for="id_sales_start_dt">販売開始日時</label>
          <we-date-picker
            id="id_sales_start_dt"
            v-model="value.saleStartTime"
            name="開催日時"
          />
        </div>
        <div class="md-layout-item md-size-25 we-form-field" style="margin-bottom: 20px;">
          <label for="id_sales_end_dt">販売終了日時</label>
          <we-date-picker
            id="id_sales_end_dt"
            v-model="value.saleEndTime"
            name="終了日時"
          />
        </div>
      </div>
      <div class="md-layout md-gutter"
        v-for="(ticket, key) in value.tickets" 
        :key="`ticket-${key}`" 
        :style="`display: ${ticket.is_deleted === true ? 'none' : 'inherit'}`"
      >
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label :for="`id_ticket_price_${key}`">チケット料金<span class="required">*</span></label>
          <we-input
            :id="`id_ticket_price_${key}`" 
            :rules="{required: true}" 
            v-model.number="ticket.price"
            name="チケット料金"
          />
        </div>
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label :for="`id_use_time_${key}`">所要時間<span class="required">*</span></label>
          <we-input
            :id="`id_use_time_${key}`" 
            :rules="{required: true}" 
            v-model.number="ticket.useTime"
            name="所要時間"
          />
        </div>
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label for="id_event_name">備考</label>
          <we-input :id="`id_description_${key}`" v-model="ticket.description" name="備考" />
        </div>
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <we-button size="small" @click="deleteTicket(ticket)" outline="red">削除</we-button>
        </div>
      </div>
      <div>
        <we-button size="small" @click="addTicket" outline="red">チケット追加</we-button>
      </div>
      <span class="form-title md-subheading" style="margin-top: 30px">オプションサービス情報</span>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label for="id_service_name">サービス名<span class="required">*</span></label>
          <we-input
            id="id_service_name" 
            :rules="{required: true}" 
            v-model="value.services[0].service"
            name="サービス名"
          />
        </div>
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label for="id_service_use_time">所要時間<span class="required">*</span></label>
          <we-input
            id="id_service_use_time" 
            :rules="{required: true}" 
            v-model.number="value.services[0].useTime"
            name="所要時間"
          />
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100 we-form-field">
          <label for="id_service_comment">備考</label>
          <we-input
            id="id_service_comment" 
            v-model="value.services[0].comment"
            name="所要時間"
          />
        </div>
      </div>
      <div class="md-layout md-gutter" 
        v-for="(template, key) in value.services[0].templates" 
        :key="`template-${key}`" 
        :style="`display: ${template.is_deleted === true ? 'none' : 'inherit'}`"
      >
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label :for="`id_template_name_${key}`">テンプレート名称<span class="required">*</span></label>
          <we-input
            :id="`id_template_name_${key}`" 
            :rules="{required: true}" 
            v-model="template.templateName"
            name="テンプレート名称"
          />
        </div>
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label :for="`id_template_price_${key}`">テンプレート金額<span class="required">*</span></label>
          <we-input
            :id="`id_template_price_${key}`" 
            :rules="{required: true}" 
            v-model.number="template.price"
            name="テンプレート金額"
          />
        </div>
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <label :for="`id_template_description_${key}`">テンプレート備考</label>
          <we-input
            :id="`id_template_description_${key}`" 
            v-model="template.description"
            name="テンプレート備考"
          />
        </div>
        <div class="md-layout-item md-size-25 md-small-size-50 we-form-field">
          <we-button size="small" @click="deleteTemplate(template)" outline="red">削除</we-button>
        </div>
        <div class="md-layout md-gutter" style="margin-bottom: 100px;">
          <div class="md-layout-item md-size-33 md-small-size-50 we-form-field">
            <label style="width: 130px; vertical-align: inherit;">背景画像</label>
            <we-image
              v-model="template.backgroundUrl" 
              name="背景画像"
              type="square"
            />
          </div>
          <div class="md-layout-item md-size-33 md-small-size-50 we-form-field">
            <label style="width: 130px; vertical-align: inherit;">位置１の画像</label>
            <we-image
              v-model="template.position1Url" 
              name="位置１の画像"
              type="square"
            />
          </div>
          <div class="md-layout-item md-size-33 md-small-size-50 we-form-field">
            <label style="width: 130px; vertical-align: inherit;">位置２の画像</label>
            <we-image
              v-model="template.position2Url" 
              name="位置２の画像"
              type="square"
            />
          </div>
        </div>
      </div>
      <div>
        <we-button size="small" @click="addTemplate" outline="red">テンプレート追加</we-button>
      </div>
    </validation-observer>
  </form>
</template>
<script>
import { common } from '../../utils';
export default {
  props: {
    value: { type: Object, default: () => ({tickets: [], services: [{templates: []}]}) },
  },
  data() {
    return {
      error: {},
    }
  },
  methods: {
    validate() {
      return this.$refs.observer.validate();
    },
    addTicket() {
      const event = Object.assign({}, this.value);
      const tickets = event.tickets.slice();
      tickets.push({});
      event.tickets = tickets;
      this.$emit('input', event);
    },
    deleteTicket(ticket) {
      const event = Object.assign({}, this.value);
      const delTicket = event.tickets.find(i => i === ticket)
      delTicket.is_deleted = true;
      this.$emit('input', event)
    },
    addTemplate() {
      const event = Object.assign({}, this.value);
      if (event.services && event.services.length > 0) {
        if (event.services[0].templates) {
          const templates = event.services[0].templates.slice();
          templates.push({});
          event.services[0].templates = templates;
        } else {
          event.services[0].templates = [{}];
        }
      } else {
        event.services = [
          {templates: [{}]}
        ]
      }
      this.$emit('input', common.clone(event));
    },
    deleteTemplate(template) {
      const event = Object.assign({}, this.value);
      const delTemplate = event.services[0].templates.find(i => i === template);
      delTemplate.is_deleted = true;
      this.$emit('input', event)
    },
  }
}
</script>
<style scoped>
.we-form >>> label {
  width: 150px;
}
</style>